<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm0-1a9.5 9.5 0 1 1 0-19 9.5 9.5 0 0 1 0 19z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
